import React from 'react'
import Navbar from './Crox'
import { Grid } from '@mui/material'

import logos from './crx.png'

import Marquee from "react-fast-marquee";
function Desktop() {
  return (
    <div className='backofdesktop'>
       <Navbar/>
    
       <div className=''>
        
<Grid container spacing={2}>
<Grid item md={12} xs={12} sm={12} lg={12} className='centeritall neonText'>
<br/><br/><br/><br/>
<br/><br/><br/>
<div className=''>
<h1 className='title'>Join Croc CAT Community </h1><br/>
  
<p class="MuiTypography-root MuiTypography-body1 ca "  style={{textAlign:"center"}}>

<a href='https://t.me/CrocCat69' class="button-89" role="button" style={{color:"#fff" , textDecoration:"none"}}>Telegram</a>
</p>
<br/>
<Marquee><img src={logos}  className='marque'/></Marquee>

</div>
<br/><br/>
<br/><br/><br/>


</Grid>





</Grid>

       </div>
  

     </div>
  )
}

export default Desktop