import React from 'react';


import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Crocify from "./Components/Crocify";
import theme from "./Theme";


// import { useEffect, useState } from 'react';







function App() {
  return (
    <div className="cursor">
      <ThemeProvider theme={theme}><>
<Routes>
        <Route
          path="/"
          element={
            <Home
            //  cursor={cursor}
            />
          }
          
        /> 
 <Route
          path="/Crocify"
          element={
            <Crocify
            //  cursor={cursor}
            />
          }
          
        /> 
      </Routes>

</></ThemeProvider>
      {/* <div class="cursor"></div>
      <div class="cursor" id='hover_display'>Read More</div> */}


    
    </div>
  );
}

export default App;
