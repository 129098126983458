import React from 'react'
import { Grid } from '@mui/material'

import logos from './cr.png'

function Navbar() {
  return (
    <div className='backofnav'>
      <br/> 
      <div className='container'>
      <Grid container spacing={2}>
<Grid item md={4} lg={4} xs={12} sm={12} className='startitall'>
<a href='/Crocify' class="button-89" role="button" style={{color:"#fff" , textDecoration:"none"}}>Crocify</a>


</Grid>
<Grid item md={4} lg={4} xs={12} sm={12} className=''>

<img src={logos}  style={{width:"100%" , borderRadius:""}}/>

</Grid>
<Grid item md={4} lg={4} xs={12} sm={12} className='enditall'>

<a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=5V9qpFo8NMFyhYHbrqY2c1TJrvzZSnRJAdj4ryPVB18n&fixed=in' class="button-89" role="button" style={{color:"#fff" , textDecoration:"none"}}>Buy Croc Cat</a>

</Grid>


</Grid>
      </div>
      <br/>   





    </div>
  )
}

export default Navbar