import React from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Desktop from "./Desktop";
import DesktopM from "./DeskMob";
import Desktopthree from "./Desktopthree";
import DesktopTwo from './Desktoptwo'

import Footer from "./Footer";
import Gib from './Meme'




const Home = () => {


  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));




  return (
    <div className="">


<div className="">
   

 
      {!smallScreen && (
        <div className="bg">
          <div className="pageCon">
       
        <Desktop/>
      

      <Desktopthree/>

        <Gib/> <DesktopTwo/>
        <Footer/>
        </div>
     </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
         <div className="bg">
           <div className="pageCon">
           <DesktopM/>
       
           <Desktopthree/>
      
 <Gib/><DesktopTwo/>
               <Footer/>
         </div>

       </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
     
     
    </div>
  );
};

export default Home;
