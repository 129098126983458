import React from 'react'
import { Grid } from '@mui/material'

import logos from './crx.png'
import Marquee from "react-fast-marquee";
function Navbar() {
  return (
    <div className='backofnav'>
      <br/>   
      <div className=''>
      <Grid container spacing={2}>

<Grid item md={12} lg={12} xs={12} sm={12} className=''>
<Marquee><img src={logos}  className='marque'/></Marquee>


</Grid>



</Grid>
      </div>
      <br/>   





    </div>
  )
}

export default Navbar