import React from 'react'
import Navbar from './Crox'
import { Grid } from '@mui/material'

import logo from './crov.jpeg'


import Marquee from "react-fast-marquee";
function Desktop() {
  return (
    <div className='backofdesktop'>
       <Navbar/>
    
       <div className='container'>
        <br/> <br/>
<Grid container spacing={2}>
<Grid item md={12} xs={12} sm={12} lg={12} className='neonText'>
<br/><br/>

<div className=''>

<h1 className='title'>Croc CAT </h1><br/>
<p class=" ca centeritall"  >
<><img src={logo}  style={{width:"60%" , borderRadius:"5px" ,border:"2px solid #000"}}/></>

</p>



</div>
<br/><br/>


</Grid>



<br/><br/><br/><br/><br/><br/>


</Grid>
<br/> <br/>
       </div>
      <br/><br/>

     </div>
  )
}

export default Desktop