import React from 'react'
import Navbar from './Navbar'
import { Grid } from '@mui/material'
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import logo from './gp.png'
import logos from './crx.png'
import rad from './image/rady.png'
import bird from './image/birds.png'


import Marquee from "react-fast-marquee";
function Desktop() {
  return (
    <div>
 <div className='backofdesktop'>
       <Navbar/>
       <div className=''>
        <br/> <br/>
<Grid container spacing={2}>
<Grid item md={12} xs={12} sm={12} lg={12} className='centeritall'>


<div class=" "><img src={logo} alt="" style={{width:"100%" }}/></div>

</Grid>



</Grid>

       </div>
       <div className='container'>
        <br/> <br/>
<Grid container spacing={2}>

<Grid item md={12} xs={12} sm={12} lg={12} className='centeritall'>
<br/><br/><br/><br/><br/><br/>
<br/><br/><br/>

<div class="MuiBox-root "><img src={logos} alt="" style={{width:"100%" }}/></div>


</Grid>

<Grid item md={12} xs={12} sm={12} lg={12} >


<br/> 


      <div style={{display:"flex"}} className='centeritall'>

<a href='https://t.me/CrocCat69'>
  <FaTelegramPlane style={{fontSize:"30px", color:"#fff"}}/>
</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<a href='https://twitter.com/croccatofficial'>
  <FaXTwitter style={{fontSize:"30px", color:"#fff"}}/>
</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=5V9qpFo8NMFyhYHbrqY2c1TJrvzZSnRJAdj4ryPVB18n&fixed=in'>
  <img src={rad} style={{width:"40px", color:"#fff"}}/>
</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<a href='https://birdeye.so/token/5V9qpFo8NMFyhYHbrqY2c1TJrvzZSnRJAdj4ryPVB18n/CynCsecWZyLwrX8zWq6iVj7ca6PL2csfATfi238wcQu5?chain=solana'>
<img src={bird} style={{width:"43px", color:"#fff"}}/>
</a>

</div>
</Grid>

<br/><br/><br/><br/><br/><br/>
<Grid item md={12} xs={12} sm={12} lg={12} className='centeritall neonText'>
<br/><br/><br/><br/>
<br/><br/><br/>
<div className=''>

<h1 className='title'>Contract Address</h1><br/>
<p class="MuiTypography-root MuiTypography-body1 ca "  style={{textAlign:"center"}}>

      CA :- 5V9qpFo8NMFyhYHbrqY2c1TJrvzZSnRJAdj4ryPVB18n</p>
</div>
<br/><br/>
<br/><br/><br/>


</Grid>

</Grid>
<br/> <br/>
       </div>
     
     </div>
      <div style={{backgroundColor:"#000"}}>
      <Marquee><img src={logos}  className='marque'/></Marquee>
      </div>
    


    </div>
   

  )
}

export default Desktop