import React from 'react';
import {
  MDBFooter,

} from 'mdb-react-ui-kit';










export default function App() {
  return (
    <MDBFooter style={{backgroundColor:"#000"}} className='text-center text-lg-left'>
    
     

      <div className='text-center p-3 footer_title' style={{ color:"#fff" }}  >
        &copy; {new Date().getFullYear()} Copyright $CROC CAT ALL RIGHTS RESERVED.
       
      </div>
    </MDBFooter>
  );
}